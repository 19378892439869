import React, { useState } from 'react';
import appContent from '../data/contentData';
import { useAppContext } from '../context/AppContext';
import '../styles/Menu.scss';


const Menu = () => {
  const links = appContent.header.links;
  const { isOpen, toggleMenu } = useAppContext();
  return (
    <nav className={`nav-menu ${isOpen ? 'open' : ''}`}>
      <div className="container">
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.path}>{link.title}</a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Menu;
