import React from 'react';
import appContent from '../data/contentData';
import '../styles/Footer.scss';

const Footer = () => {
  return (
    <footer>
      {appContent.footer.copyright} <a href="{appContent.footer.privacyPolicyLink.path}">{appContent.footer.privacyPolicyLink.text}</a>.
    </footer>
  );
};

export default Footer;
