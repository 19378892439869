// ImgPlaceholder.js

import React, { useEffect, useState, useRef } from 'react';
import placeholderImgUrl from '../img/AdobeStock_621775102.gif'; // with import
import '../styles/ImgPlaceholder.scss';

const ImgPlaceholder = () => {
  return (
    <div className="img-placeholder" style={{ background: `url(${placeholderImgUrl}) no-repeat center center`, backgroundSize: 'cover' }}>
    </div>
  );
};

export default ImgPlaceholder;
