import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import MadLibResultImages from './MadLibResultImages';
import appContent from '../data/contentData'; // Adjust the path as necessary
import '../styles/MadLibResult.scss';

const MadLibResult = ({ loadedMadLib, completedMadLib, madLibImages }) => {
  const data = completedMadLib;
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(data)
  });
  return (
    <div className="mad-lib-result">
      <div className="mad-lib-result-text">
        <div className="container">
          <div
            dangerouslySetInnerHTML={sanitizedData()}
          />
        </div>
      </div>
        <MadLibResultImages loadedMadLib={loadedMadLib} completedMadLib={sanitizedData()} madLibImages={madLibImages} />
    </div>
  );
};

export default MadLibResult;