import React, { useState } from 'react';
import appContent from '../data/contentData'; 
import { useAppContext } from '../context/AppContext';
import '../styles/MenuToggle.scss'; // Import the CSS for styling

const MenuToggle = () => {
  const { isOpen, toggleMenu } = useAppContext();
  return (
      <div className={`menu-toggle ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
  );
};

export default MenuToggle;
