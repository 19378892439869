import React, { useEffect } from 'react';
import '../styles/SocialShare.scss';

const SocialShare = () => {
  useEffect(() => {
    // Function to load the ShareThis script
    const loadScript = () => {
      // Check if the script is already loaded
      if (document.querySelector('script[src="https://platform-api.sharethis.com/js/sharethis.js#property=65c67a5c3625b4001a8bcf76&product=inline-share-buttons&source=platform"]')) {
        return;
      }

      // Create script element
      const script = document.createElement('script');
      script.src = "https://platform-api.sharethis.com/js/sharethis.js#property=65c67a5c3625b4001a8bcf76&product=inline-share-buttons&source=platform";
      script.async = true;

      // Append script to the document body
      document.body.appendChild(script);
    };

    // Call the function to load the script
    loadScript();

    // Optional: Cleanup function to remove the script when the component unmounts
    return () => {
      const script = document.querySelector('script[src="https://platform-api.sharethis.com/js/sharethis.js#property=65c67a5c3625b4001a8bcf76&product=inline-share-buttons&source=platform"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <div className="social-share">
      <div className="sharethis-inline-share-buttons"></div>
    </div>
  );
};

export default SocialShare;
