// Stability.js

import React, { useEffect, useState, useRef } from 'react';
import { useAppContext } from '../context/AppContext';
import ImgPlaceholder from './ImgPlaceholder'; // with import

const Stability = ({ imagePrompt, loadedImage }) => {
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const { updateDataObject, dataObj } = useAppContext();

  const engineId = process.env.REACT_APP_STABILITY_ENGINE_ID;
  const apiHost = process.env.REACT_APP_STABILITY_API_HOST;
  const apiKey = process.env.REACT_APP_STABILITY_API_KEY;

  const img_data_uri_scheme = 'data:image/jpg;base64,';

  useEffect(() => {
    if(loadedImage) {
      setImageURL(loadedImage);
      setLoading(false);
    } else {
        generateStabilityImage();      
    }
  }, [imagePrompt]);

  const generateStabilityImage = async () => {
    try {
      setLoading(true);
      let stability_options = {
        method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({
            text_prompts: [
              {
                "text": imagePrompt,
                "weight": 1.1
              },
              {
                "text": 'Pixar-style, Pixar style, style is a blend of realism and stylization',
                "weight": 1
              },
              {
                "text": 'holiday, winter, New Years, adorable, full color, vibrant',
                "weight": 1
              },
              {
                "text": "blurry, deformed faces, missing eyes, skewed faces, cross eyed, missing limbs, extra libs, deformed bodies, missing body parts, extra body parts, confusing gender, dates, years, writing, words, letters, text",
                "weight": -1
              }
            ],      
            cfg_scale: 15, //default is 7
            height: 1024,
            width: 1024,
            steps: 50,
            samples: 1,
            style_preset: "enhance",
          }),
      }
      const response = await fetch(`${apiHost}/v1/generation/${engineId}/text-to-image`,stability_options); 
      if (!response.ok) {
      throw new Error(`Non-200 response: ${await response.text()}`)
      }
      const data = await response.json();
      const imageURL = img_data_uri_scheme + data.artifacts[0].base64;
      setImageURL(imageURL);
      setLoading(false);
      updateDataObject({
        MadLibID: dataObj.MadLibID,
        ImageURL_Stability: imageURL
      });
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Add your stability image display logic here */}
      <h4>Stability</h4>
      {loading ? (
        <ImgPlaceholder />
      ) : (
        <img src={imageURL} alt="Image generated by Stability" />
      )}
    </div>
  );
};

export default Stability;