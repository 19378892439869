import React from 'react';

import Header from '../parts/Header';
import Hero from '../components/Hero';
import MadLibContainer from '../components/MadLibContainer';
import Footer from '../parts/Footer';

const Home = () => {
  return (
    <div className="page-content">
      <Hero pageKey="home" />
      <MadLibContainer />
    </div>
  );
}

export default Home;
