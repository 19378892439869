import React from 'react';

import Header from '../parts/Header';
import Hero from '../components/Hero';
import Accordions from '../components/Accordions';
import FullwidthCTA from '../components/FullwidthCTA';
import Footer from '../parts/Footer';
import '../styles/FAQs.scss';
import appContent from '../data/contentData'; 

const FAQs = () => {
  const accordionContent = appContent.pages.faqs.accordions;
  return (
    <div className="page-content">
      <Hero pageKey="faqs" />
      <Accordions accordionContent={accordionContent} />
      <FullwidthCTA />
    </div>
  );
}

export default FAQs;
