// MadLibResultImages.js

import React, { useState, useEffect, useRef } from 'react';
import ImgStability from './ImgStability';
import ImgDalle from './ImgDalle';
import ImgMidJourney from './ImgMidJourney';
import { useAppContext } from '../context/AppContext';
import appContent from '../data/contentData'; // Adjust the path as necessary
import '../styles/MadLibResultImages.scss';

const MadLibResultImages = ({ loadedMadLib, completedMadLib, madLibImages }) => {
  const [imagePrompt, setImagePrompt] = useState('');
  const [imagePromptReady, setImagePromptReady] = useState(false);
  const { updateDataObject } = useAppContext();
  const prevCompletedMadLib = useRef('');
console.log(madLibImages);
  useEffect(() => {
    let checkIfMadLibIsNew = completedMadLib.__html !== prevCompletedMadLib.current.__html;
    if(checkIfMadLibIsNew && loadedMadLib === 'false' && completedMadLib) {
      generateImagePrompt();
      prevCompletedMadLib.current = completedMadLib;
    } else if (!completedMadLib) {
      setImagePromptReady(false); // Reset the prompt readiness
    }
  }, [completedMadLib]);

  const generateImagePrompt = async () => {
    const openAIEngine = process.env.REACT_APP_OPENAI_ENGINE;
    const openAIapiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const regex = /(<([^>]+)>)/gi;
    const completedMadLibText = completedMadLib.__html.split('</h2>')[1].replace(regex, '');
    const prompt = `
      Use the following steps to provide me a clear and concise sentence describing a cover image for the following short story: "${completedMadLibText}".

      1: **Read:** Read the entire paragraph several times and make sure you understand it.
      2: **Select a scene:** Imagine the paragraph is a short story, and you need to design an award-winning cover image for it. Pick the single most important scene of the book to feature on the cover image. Make sure to incorporate the most important and remarkable details of the story.
      3: **Describe the scene:** Describe in one clear and concise sentence what the cover image for this book should be. Describe the image literally, as if you were already looking at it. Only include details and adjectives that can be shown.
      4: **Edit your work:** Re-read the sentence you wrote. Identify which details and words can be removed from the sentence without changing what will be shown in the cover image. Rewrite the sentence to make it as concise and to the point as possible. Read the rewritten sentence to make sure that it still portrays the initial paragraph accurately and hasn't confused any details. Rewrite if needed. The final sentence should only be about what the image shows and only include the most important details. Remove everything from the sentence that cannot be shown visually. 
      5. **Format:** The format of your response must be: The cover image for this book would show...
      6. **Final submission:** Submit only the final sentence, exactly in the format outlined in step 5.
   `;
    try {
      const response = await fetch('https://api.openai.com/v1/engines/'+openAIEngine+'/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${openAIapiKey}`
        },
        body: JSON.stringify({
          prompt,
          max_tokens: 400
        })
      });
      const data = await response.json();
      const result = data.choices[0].text.trim().replace('The cover image for this book would show ', '');
      setImagePrompt(result);
      setImagePromptReady(true); // Set the prompt as ready after generation
      updateDataObject({
        ImagePrompt: result
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="mad-lib-result-images">
        <div className="container">
          <h3>{appContent.pages.home.results.headline}</h3>
          <div className="columns">
            {imagePromptReady && loadedMadLib === 'false' && (
                <>
                  <ImgDalle imagePrompt={imagePrompt} />
                  <ImgStability imagePrompt={imagePrompt} />
                  <ImgMidJourney imagePrompt={imagePrompt} />
                </>
            )}
            {loadedMadLib === 'true' && (
                <>
                  <ImgDalle loadedImage={madLibImages[0]} />
                  <ImgStability loadedImage={madLibImages[1]} />
                  <ImgMidJourney loadedImage={madLibImages[2]} />
                </>
            )}
          </div>
      </div>
    </div>
  );
};

export default MadLibResultImages;
