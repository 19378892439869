import React, { useState, useCallback } from 'react';
import appContent from '../data/contentData'; 
import { useAppContext } from '../context/AppContext';
import '../styles/Hero.scss';

const Hero = ({ pageKey }) => {
  const heroContent = appContent.pages[pageKey]?.hero;
  const { isMadLibCompleted } = useAppContext();
  const scrollToElement = useCallback((event) => {
    const scrollToClass = event.target.getAttribute('data-scroll-to');
    const element = document.querySelector(`.${scrollToClass}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);
  if (pageKey === 'home' && isMadLibCompleted) {
    return null;
  }
  return (
    <div id="hero" className={pageKey}>
      <div className="container">
        <h1>{heroContent.headline}</h1>
        {heroContent.text && (
          <p>{heroContent.text}</p>
        )}
        {heroContent.buttonText && heroContent.buttonLink && (
          <div id="button-border">
            <button onClick={scrollToElement} data-scroll-to={heroContent.buttonLink}>{heroContent.buttonText}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
