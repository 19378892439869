import React from 'react';
import appContent from '../data/contentData'; // Adjust the path as necessary
import '../styles/Header.scss';
import logo from '../img/logo.svg'; // with import
import MenuToggle from './MenuToggle'; // with import
import Menu from './Menu'; // with import

const Header = () => {
  const links = appContent.header.links;
  return (
    <header>
      <MenuToggle />
      <a href="/"><img id="logo" src={logo} /></a>
      <Menu />
    </header>
  );
};

export default Header;
