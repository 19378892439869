// Filename: App.js 

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import FAQs from './pages/FAQs';
import AlertBanner from './parts/AlertBanner';
import Header from './parts/Header';
import Hero from './components/Hero';
import SocialShare from './parts/SocialShare';
import Footer from './parts/Footer';

import { useAppContext } from './context/AppContext'; // Adjust the import path according to your file structure

function App() { 
  const { isOpen } = useAppContext();
  useEffect(() => {
    const root = document.getElementById('root');
    if (isOpen) {
      root.classList.add('menu-open');
    } else {
      root.classList.remove('menu-open');
    }
  }, [isOpen]); // Dependency array ensures this effect runs only when isOpen changes
  return ( 
      <Router>
        <AlertBanner />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faqs" element={<FAQs />} />
        </Routes>
        <SocialShare />
        <Footer />
      </Router>
  ); 
} 

export default App; 
