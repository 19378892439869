import React from 'react';
import appContent from '../data/contentData'; // Adjust the path as necessary
import '../styles/AlertBanner.scss';

const AlertBanner = () => {
  return (
      <div id="alert-banner">{appContent.header.alertBanner}</div>
  );
};

export default AlertBanner;
