import React, { useState, useCallback } from 'react';
import appContent from '../data/contentData'; // Adjust the path as necessary
import '../styles/FullwidthCTA.scss';

const FullwidthCTA = () => {
  return (
    <div className="fullwidth-cta">
      <div className="container">
        <h2>{appContent.pages.faqs.cta.headline}</h2>
        <a href={appContent.pages.faqs.cta.button.path}>{appContent.pages.faqs.cta.button.title}</a>
      </div>
    </div>
  );
};

export default FullwidthCTA;
