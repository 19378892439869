const appContent = {
  header: {
    alertBanner: 'White Rhino presents',
    links: [
        {
          title: 'Home',
          path: '/',
        },
        {
          title: 'FAQs',
          path: '/faqs',
        }
      ]
  },
  footer: {
    copyright: '© 2024 White Rhino. All rights reserved.',
    privacyPolicyLink: {
      title: 'Privacy Policy',
      path: '#',
    }
  },
  pages: {
    home: {
      hero: {
        headline: 'AI-Powered Story and Image Generation',
        text: 'Every time you load the Mad-Lib-O-Matic, we’re using AI to generate a one-of-a-kind story that can only be completed with your wacky word choice. We\'ve also integrated 3 different image generation tools to bring your story to life visually.',
        buttonLink: 'mad-lib-form',
        buttonText: 'Take it for a spin!',
      },
      form: {
        headline: 'Fill in the fields to generate',
      },
      results: {
        headline: 'Compare AI Image Generators',
        placeholder: {
          text: 'Why is it taking so long to load?',
          button: {
            title: 'View FAQs',
            path: '/faqs'
          }
        }
      }
    },
    faqs: {
      hero: {
        headline: 'Frequently Asked Questions',
      },
      accordions: [
        {
          title: 'How did you use AI to build Madlib-O-Matic?',
          content: `<p>We put AI to work in a fun and innovative way to create Madlib-O-Matic. Here's how it went down:</p> 

          <p>First, we asked the AI to craft a witty and engaging Mad Lib, complete with a catchy title and placeholders for words to spark creativity. We made sure the AI checked its work for accuracy, making sure the format and the number of blanks were just right. Then, we turned this Mad Lib into an interactive HTML form for users to fill out with their own words, adding a personal touch to the story.</p>

          <p>After users submit their hilarious creations, our AI steps in again, this time to tidy up the grammar and make sure everything reads smoothly, adjusting details like articles to fit the users' inputs.</p>

          <p>But we didn't stop there. To bring the completed Mad Libs to life visually, we had the AI take a moment to imagine the story as a hilarious short tale and then design a funny book cover for it. This step was key to getting imaginative and engaging image prompts for our image generation AI partners, which then provided us with fantastic images to match the stories.</p>

          <p>In essence, we used AI for all the creative heavy lifting: thinking up the Mad Lib, fixing grammar, dreaming up image prompts, and generating images. We chose not to use AI for creating the HTML form, though, as we found it quicker and more cost-effective to do that with a tiny bit of coding magic. This way, we kept the fun rolling without unnecessary delays or expenses.</p>

          <p>Fun fact: we even used AI to help us transform the more technical description of how we built this tool into the fun overview you just read.</p>`
        },
        {
          title: 'Why is an image taking so long to generate?',
          content: `The time it takes for an image generation AI to generate images compared to other AI models can depend on several factors. Each image generation AI operates differently based on its architecture, optimization, server capacity, and the complexity of the prompts it receives. Here are some potential reasons why a specific AI might take longer to generate images:
                    <ul>
                      <li><strong>Model Complexity</strong>: Highly complex models that aim to produce high-quality images can have a large number of parameters and intricate processing layers, requiring more computation time.</li>
                      <li><strong>Image Resolution and Detail</strong>: Generating high-resolution images with a lot of details takes longer. Platforms focusing on higher-quality images may experience longer generation times.</li>
                      <li><strong>Server Load and Scaling</strong>: The time it takes to generate an image can be affected by the server load and how well the service scales with increased demand. Limited server capacity can result in slower image generation times.</li>
                      <li><strong>Optimization and Efficiency</strong>: Different AI models and platforms are optimized differently, affecting their efficiency in utilizing hardware resources and algorithmic speed, impacting generation time.</li>
                      <li><strong>User Demand and Queueing System</strong>: High user demand and the queueing system in place can vary the processing time for requests. High demand might lead to longer wait times as requests are queued.</li>
                      <li><strong>Customization and Features</strong>: More customization options or features allowing for complex and nuanced image generation can contribute to longer generation times due to additional processing required.</li>
                  </ul>`
        }
      ],
      cta: {
        headline: 'Generate a New Mad-Lib',
        button: {
          title: 'Generate my mad-lib!',
          path: '/'
        }
      }
    }    
  },
  components: {
    madLibForm: {
      title: 'Fill in the fields to generate',
      buttonText: 'Generate my mad-lib!',
    }    
  }
};

export default appContent;
